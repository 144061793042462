import { createLogic } from 'redux-logic';
import moment from 'moment';
import {
  GET_ORG_AUTOSHIPS_PENDING,
  GET_PERSONAL_AUTOSHIPS_PENDING,
  UPDATE_AUTOSHIP_SHIPPING_ADDRESS_PENDING,
  CANCEL_AUTOSHIP_PENDING,
  UPDATE_AUTOSHIP_PAYMENT_METHOD_PENDING,
  UPDATE_AUTOSHIP_CONTACT_PENDING,
  REMOVE_AUTOSHIP_ITEM_PENDING,
  CHANGE_AUTOSHIP_ITEM_QUANTITY_PENDING,
  TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_PENDING,
  TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_PENDING,
  CHANGE_AUTOSHIP_FREQUENCY_PENDING,
  CHANGE_AUTOSHIP_NEXT_ORDER_DATE_PENDING,
  CHANGE_AUTOSHIP_NAME_PENDING,
  TRANSFER_AUTOSHIP_ITEM_TO_NEW_PENDING,
  SUBMIT_AUTOSHIP_ORDER_PENDING,
  SKIP_AUTOSHIP_NEXT_ORDER_PENDING,
  ADD_AUTOSHIP_ITEMS_PENDING,
  PARSE_DATE_FORMAT,
  GET_AUTOSHIP_DETAILS_PENDING,
  REACTIVATE_AUTOSHIP_PENDING,
  GET_DEFAULT_BILLING_ACCOUNT_ID_PENDING
} from '../../constants';
import {
  getOrgAutoshipsPending,
  getOrgAutoshipsError,
  getOrgAutoshipsSuccess,
  getPersonalAutoshipsPending,
  getPersonalAutoshipsError,
  getPersonalAutoshipsSuccess,
  getProductAllDetailsPending,
  setBannerState,
  updateAutoshipShippingAddressSuccess,
  updateAutoshipShippingAddressError,
  updateAutoshipPaymentMethodSuccess,
  updateAutoshipPaymentMethodError,
  updateAutoshipContactSuccess,
  updateAutoshipContactError,
  cancelAutoshipSuccess,
  cancelAutoshipError,
  removeAutoshipItemSuccess,
  removeAutoshipItemError,
  changeAutoshipItemQuantitySuccess,
  changeAutoshipItemQuantityError,
  transferAutoshipItemToExistingSuccess,
  transferAutoshipItemToExistingError,
  changeAutoshipItemQuantityPending,
  transferAutoshipItemToExistingPending,
  transferAutoshipItemToExistingWithQuantitySuccess,
  transferAutoshipItemToExistingWithQuantityError,
  changeAutoshipFrequencySuccess,
  changeAutoshipFrequencyError,
  changeAutoshipNextOrderDateSuccess,
  changeAutoshipNextOrderDateError,
  changeAutoshipNameSuccess,
  changeAutoshipNameError,
  submitAutoshipOrderSuccess,
  submitAutoshipOrderError,
  removeAutoshipItemPending,
  transferAutoshipItemToNewSuccess,
  transferAutoshipItemToNewError,
  getPaymentMethodsPending,
  skipAutoshipNextOrderSuccess,
  skipAutoshipNextOrderError,
  addAutoshipItemsSuccess,
  addAutoshipItemsError,
  getAutoshipDetailsSuccess,
  getAutoshipDetailsError,
  getAutoshipDetailsPending,
  reactivateAutoshipSuccess,
  reactivateAutoshipError,
  getDefaultBillingAccountIdSuccess,
  getDefaultBillingAccountIdError,
  addAccountShippingAddressSuccess,
  transferAutoshipBetweenAccountAddresses,
} from '../../actions/AppActions';
import { getMatchingPaymentMethod, formatAutoshipAddress, formatAutoshipPaymentMethod, getCurrentUser, formatContact } from './utils';
import { getOrganizationId } from '../../utils/autoshipUtils';

const PAGE_SIZE = '20';

const getPaginatedSubscriptions = async (action, pageNumber = undefined, status) => {
  const { data: page } = await action({ pageSize: PAGE_SIZE, pageNumber, status});
  if (
    page.pages?.self == null ||
    page.pages?.self === page.pages?.last ||
    page.pages?.next == null
  ) {
    return {
      autoships: page.subscriptions,
      errors: page.errors ?? []
    };
  } else {
    const rest = await getPaginatedSubscriptions(action, page.pages.next, status);
    return {
      autoships: [...(page.subscriptions ?? []), ...rest.autoships],
      errors: [...(page.errors ?? []), ...rest.errors]
    };
  }
};

const getSubscriptions = async (action, pageNumber = undefined) => {
  const active = await getPaginatedSubscriptions(action, pageNumber, "active");
  const suspended = await getPaginatedSubscriptions(action, pageNumber, "suspended");
  return {
    autoships: [...(active.autoships ?? []), ...(suspended.autoships ?? [])],
    errors: [...(active.errors ?? []), ...(suspended.errors ?? [])]
  };
};

export const getPersonalAutoships = createLogic({
  type: GET_PERSONAL_AUTOSHIPS_PENDING,
  latest: true,
  process({ httpClient, getState }, dispatch, done) {
    const intl = getState().intl.messages;

    getSubscriptions(pageParams =>
      httpClient(dispatch).get('/autoships', {
        params: {
          version: '2',
          ...pageParams
        }
      })
    )
      .then(({ autoships, errors }) => {
        dispatch(getPersonalAutoshipsSuccess(autoships, errors));

        const itemNumbers = autoships
          .reduce((items, autoship) => items.concat(autoship.items), [])
          .map(item => item.productSku);

        if (itemNumbers.length > 0) {
          dispatch(getProductAllDetailsPending(itemNumbers));
        }

        done();
      })
      .catch(err => {
        const bannerState = {
          data: { type: 'error', message: intl.Errors.Autoships.Error_Retrieving_Personal }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getPersonalAutoshipsError(err));
        done();
      });
  }
});

export const getOrgAutoships = createLogic({
  type: GET_ORG_AUTOSHIPS_PENDING,
  process({ httpClient, getState, action }, dispatch, done) {
    const { organizationId } = action.payload;
    const intl = getState().intl.messages;

    getSubscriptions(pageParams =>
      httpClient(dispatch).get(`/organizations/${organizationId}/autoships`, {
        params: {
          version: '2',
          ...pageParams
        }
      })
    )
      .then(({ autoships, errors }) => {
        dispatch(getOrgAutoshipsSuccess(organizationId, autoships, errors));

        const itemNumbers = autoships
          .reduce((items, autoship) => items.concat(autoship.items), [])
          .map(item => item.productSku);

        if (itemNumbers.length > 0) {
          dispatch(getProductAllDetailsPending(itemNumbers));
        }

        done();
      })
      .catch(err => {
        const bannerState = {
          data: { type: 'error', message: intl.Errors.Autoships.Error_Retrieving_Org }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getOrgAutoshipsError(organizationId, err));
        done();
      });
  }
});

export const getAutoshipDetails = createLogic({
  type: GET_AUTOSHIP_DETAILS_PENDING,
  process({ httpClient, action }, dispatch, done) {
    const { autoshipId } = action.payload;

    httpClient(dispatch).get(`/autoships/${autoshipId}`)
      .then((response) => {
        const autoship = response.data.subscription;
        dispatch(getAutoshipDetailsSuccess(autoshipId, autoship));

        const itemNumbers = autoship.items.map((item) => item.productSku);
        let bundleNumbers = [];
        autoship.items
          .filter((item) => item.bundleComponentItems != null)
          .map((bundle) =>
            bundle.bundleComponentItems.map((bundleInfo) =>
              bundleNumbers.push(bundleInfo.productSku)
            )
          );

        const allItemNumbers = [...itemNumbers, ...bundleNumbers];
        if (allItemNumbers.length > 0) {
          dispatch(getProductAllDetailsPending(allItemNumbers));
        }
      })
      .catch((error) => {
        const status = error.response?.status;
        dispatch(getAutoshipDetailsError(autoshipId, error, status));
      })
      .finally(() => done());
  }
});

const refetchUpdatedAutoships = (dispatch, ids, { fetchList } = {}) => {
  const autoshipIds = Array.isArray(ids) ? ids : [ids];

  if (fetchList) {
    const orgIds = new Set(autoshipIds.map(id => id.organizationId));
    orgIds.forEach(organizationId => {
      if (organizationId) {
        dispatch(getOrgAutoshipsPending(organizationId));
      } else {
        dispatch(getPersonalAutoshipsPending());
      }
    });
  } else {
    autoshipIds.forEach(({ autoshipId }) => {
      dispatch(getAutoshipDetailsPending(autoshipId));
    });
  }
};

const postAutoshipShippingAddress = (httpClient, dispatch, { autoshipId, address }) =>
  httpClient(dispatch).post('/subscription-commands/updatesubscriptionshippingaddress',
    {
      id: autoshipId,
      shippingAddress: {
        ...address,
        country: address.countryCode,
        state: address.countrySubdivision,
        zip: address.postalCode,
        addressType: address.type,
        phone: address.phoneNumber,
      }
    });

export const updateAutoshipShippingAddress = createLogic({
  type: UPDATE_AUTOSHIP_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const {
      actions,
      address, 
      autoshipId, 
      organizationId, 
      billingAccountId, 
      close,
    } = action.payload;
    const intl = getState().intl.messages;

    actions?.setProcessingAction?.(true);

    //Edit Address
    if (address.id) {
      postAutoshipShippingAddress(httpClient, dispatch, { autoshipId, address })
        .then(() => {
          dispatch(updateAutoshipShippingAddressSuccess());
          if (organizationId && billingAccountId) {
            dispatch(transferAutoshipBetweenAccountAddresses({
              billingAccountId,
              organizationId,
              autoshipId,
              newAddressId: address.id
            }));
          }

          actions?.setProcessingAction?.(false);
          actions?.onCloseModal?.();
          close?.();
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });
          done();
        })
        .catch(err => {
          actions?.setProcessingAction?.(false);
          actions?.onCloseModal?.();
          const bannerState = {
            data: { type: 'error', message: intl.Errors.Autoships.Error_Updating_Address }
          };
          dispatch(setBannerState(bannerState));
          dispatch(updateAutoshipShippingAddressError({
            message: intl.Errors.Autoships.Error_Updating_Address,
          }));
          done();
        });
    } else {
      //Add Address
      const endpointUrl = (organizationId && billingAccountId)
        ? `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses`
        : '/shipping-addresses';

      const addressToCreate = { ...address };

      if (organizationId && billingAccountId) addressToCreate.category = 'SHIPPING';

      httpClient(dispatch)
        .post(endpointUrl, addressToCreate, {
          params: {
            overrideAddressNormalization: actions?.overrideAddress,
          },
        })
        .catch((error) =>
          Promise.reject({
            error,
            alreadyCaught: true,
            message: intl.Errors.Autoships.Error_Confirm_Address,
          })
        )
        .then((res) => res.data)
        .then((address) => {
          if (organizationId && billingAccountId) {
            dispatch(addAccountShippingAddressSuccess({address, billingAccountId, organizationId}));
            dispatch(transferAutoshipBetweenAccountAddresses({billingAccountId, organizationId, autoshipId, newAddressId: address.id}));
          }

          if (address.organizationName) {
            address.organization = address.organizationName;
            delete address.organizationName;
          }

          return postAutoshipShippingAddress(httpClient, dispatch, {
            autoshipId,
            address,
          });
        })
        .catch((error) => {
          return error.alreadyCaught
            ? Promise.reject(error)
            : Promise.reject({ error, message: intl.Errors.Autoships.Error_Updating_Address });
        })
        .then(() => {
          dispatch(updateAutoshipShippingAddressSuccess());
          actions?.setProcessingAction?.(false);
          actions?.onCloseModal?.();
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

          close?.();
          done();
        })
        .catch(({ error, message }) => {
          actions?.onCloseModal?.();
          actions?.setSubmitting?.(false);
          actions?.setProcessingAction?.(false);
          const bannerState = {
            data: { type: 'error', message }
          };
          dispatch(updateAutoshipShippingAddressError({}));
          dispatch(setBannerState(bannerState));
          done();
        });
    }
  }
});


const updatePaymentMethod = async (state, client, payload) => {
  const { autoshipId, paymentMethodId, paymentMethodType, organizationId } = payload;

  const { paymentMethod } = getMatchingPaymentMethod(state, { paymentMethodId, organizationId });

  const billingAddress = paymentMethodType === 'billing-account'
      ? paymentMethod.billingAddress
      : paymentMethod.address;

  const user = getCurrentUser(state);

  return client.post('/subscription-commands/updatesubscriptionpaymentmethod', {
      id: autoshipId,
      paymentMethod: formatAutoshipPaymentMethod(paymentMethodType, paymentMethod),
      billingAddress: formatAutoshipAddress(billingAddress, user),
    })
    .then(res => res.data);
};

export const updateAutoshipPaymentMethod = createLogic({
  type: UPDATE_AUTOSHIP_PAYMENT_METHOD_PENDING,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const { autoshipId, organizationId, close } = action.payload;
    const state = getState();
    const intl = state.intl.messages;

    updatePaymentMethod(state, httpClient(dispatch), action.payload)
      .then(data => {
        dispatch(updateAutoshipPaymentMethodSuccess(data));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        close?.();
        done();
      })
      .catch(error => {
        const bannerState = {
          data: {
            type: 'error',
            message: intl.Errors.Autoships.Error_Updating_Payment_Method
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(updateAutoshipPaymentMethodError(error));
        done();
      });
  }
});
const updateContact = async (state, client, payload) => {
  const { autoshipId, contact, paymentMethodId, paymentMethodType, organizationId } = payload;
  const apiPayload = {
    contact: formatContact(contact),
  };

  if (paymentMethodId && paymentMethodType) {
    const { paymentMethod } = getMatchingPaymentMethod(state, { paymentMethodId, organizationId });
    const user = getCurrentUser(state);
    const billingAddress = paymentMethodType === 'billing-account'
        ? paymentMethod.billingAddress
        : paymentMethod.address;

    apiPayload.payment = {
      paymentMethod: formatAutoshipPaymentMethod(paymentMethodType, paymentMethod),
      billingAddress: formatAutoshipAddress(billingAddress, user),
    };
  }

  return client.put(`/subscriptions/${autoshipId}/organization/contact`, apiPayload)
    .then(res => res.data);
};

export const updateAutoshipContact = createLogic({
  type: UPDATE_AUTOSHIP_CONTACT_PENDING,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const { autoshipId, organizationId, close } = action.payload;
    const state = getState();
    const intl = state.intl.messages;

    updateContact(state, httpClient(dispatch), action.payload)
      .then(data => {
        dispatch(updateAutoshipContactSuccess(data));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        close?.();
        done();
      })
      .catch(error => {
        const bannerState = {
          data: {
            type: 'error',
            message: intl.Errors.Autoships.Error_Updating_Contact
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(updateAutoshipContactError(error));
        done();
      });
  }
});

export const cancelAutoshipLogic = createLogic({
  type: CANCEL_AUTOSHIP_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { autoship, cancelReason: { reason, explanation } } = action.payload;

    httpClient(dispatch).post('/subscription-commands/cancelsubscription', {
        reasonCancelled: reason,
        id: autoship._id,
        ...(explanation != null && explanation !== ''
          ? { explanation: explanation }
          : {}
        )
      })
      .then(() => {
        dispatch(cancelAutoshipSuccess(autoship._id));

        refetchUpdatedAutoships(dispatch, {
          autoshipId: autoship._id,
          organizationId: autoship.associatedOrganization?.id
        });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(cancelAutoshipError(autoship._id, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const reactivateAutoshipLogic = createLogic({
  type: REACTIVATE_AUTOSHIP_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { autoshipId, organizationId } = action.payload;

    httpClient(dispatch).post(`/subscriptions/${autoshipId}/reactivate`, {})
      .then(() => {
        dispatch(reactivateAutoshipSuccess(autoshipId, organizationId));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(reactivateAutoshipError(autoshipId, organizationId, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const removeAutoshipItem = createLogic({
  type: REMOVE_AUTOSHIP_ITEM_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { autoshipId, organizationId, productSku } = action.payload;

    httpClient(dispatch).delete(`/subscriptions/${autoshipId}/items`, {
        data: {
          productSkus: [productSku],
        }
      })
      .then(() => {
        dispatch(removeAutoshipItemSuccess(autoshipId, { productSku, organizationId }));

        if (action.meta.refetchAutoships) {
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });
        }

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(removeAutoshipItemError(autoshipId, { productSku, organizationId }, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const changeItemQuantity = createLogic({
  type: CHANGE_AUTOSHIP_ITEM_QUANTITY_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { autoshipId, organizationId, productSku, newQuantity } = action.payload;

    httpClient(dispatch).put(`/subscriptions/${autoshipId}/items/${productSku}/quantity`, {
        newQuantity
      })
      .then(() => {
        dispatch(changeAutoshipItemQuantitySuccess(autoshipId, { productSku, newQuantity, organizationId }));

        if (action.meta.refetchAutoships) {
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });
        }

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(changeAutoshipItemQuantityError(autoshipId, { productSku, newQuantity, organizationId }, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const transferItemToExisting = createLogic({
  type: TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_PENDING,

  process({ httpClient, getState, action }, dispatch, done) {
    const { originId, destinationId, productSku, organizationId } = action.payload;

    const isLastItem = getState().app.getIn(['autoships', 'details', originId, 'data', 'items'])
        ?.every(item => item.get('productSku') === productSku) ?? false;

    httpClient(dispatch).post(`/subscriptions/${originId}/items/transfer`, {
        destinationSubscriptionId: destinationId,
        productSkus: [productSku]
      })
      .then(() => {
        dispatch(transferAutoshipItemToExistingSuccess({ originId, destinationId, productSku, organizationId }));

        if (action.meta.refetchAutoships) {
          const autoshipsToRefetch = [
            !isLastItem && { autoshipId: originId, organizationId },
            { autoshipId: destinationId, organizationId },
          ].filter(Boolean);

          refetchUpdatedAutoships(dispatch, autoshipsToRefetch);
        }

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(transferAutoshipItemToExistingError({ originId, destinationId, productSku, organizationId }, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const transferItemToExistingWithQuantity = createLogic({
  type: TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_PENDING,

  process({ action, getState }, dispatch, done) {
    const { originId, destinationId, productSku, newQuantity, organizationId } = action.payload;

    const isLastItem = getState().app.getIn(['autoships', 'details', originId, 'data', 'items'])
        ?.every(item => item.get('productSku') === productSku) ?? false;

    const changeQuantity = () =>
      new Promise((resolve, reject) => {
        dispatch(changeAutoshipItemQuantityPending(
            originId,
            { productSku, newQuantity, organizationId },
            { onSuccess: resolve, onError: reject, refetchAutoships: false }
          ));
      })
        .catch(error => Promise.reject({ errorSource: 'change-quantity', error }));

  const transfer = () =>
      new Promise((resolve, reject) => {
        dispatch(transferAutoshipItemToExistingPending(
          { originId, destinationId, productSku, organizationId },
          { onSuccess: resolve, onError: reject, refetchAutoships: false }
        ));
      })
        .catch(error => Promise.reject({ errorSource: 'transfer', error }));

    changeQuantity()
      .then(() => transfer())
      .then(() => {
        dispatch(transferAutoshipItemToExistingWithQuantitySuccess({ originId, destinationId, organizationId, productSku, newQuantity }));

        if (action.meta.refetchAutoships) {
          const autoshipsToRefetch = [
            !isLastItem && { autoshipId: originId, organizationId },
            { autoshipId: destinationId, organizationId },
          ].filter(Boolean);

          refetchUpdatedAutoships(dispatch, autoshipsToRefetch);
        }

        action.meta?.onSuccess?.();
      })
      .catch(({ errorSource, error }) => {
        dispatch(transferAutoshipItemToExistingWithQuantityError(
          { originId, destinationId, organizationId, productSku, newQuantity },
          errorSource,
          error
        ));

        if (errorSource === 'transfer' && action.meta.refetchAutoships) {
          refetchUpdatedAutoships(dispatch, [
            { autoshipId: originId, organizationId },
          ]);
        }

        action.meta?.onError?.(errorSource, error);
      })
      .finally(() => done());
  }
});

const getPMs = (state) => {
  const creditCards = state.app.getIn(['currentUser', 'paymentMethods', 'creditCards']).toJS();
  const paypal = state.app.getIn(['currentUser', 'paymentMethods', 'paypal']).toJS();

  return { creditCards, paypal };
};

const ensurePaymentMethodsHaveBeenFetched = async ({ getState, dispatch }) => {
  const paymentMethods = getPMs(getState());

  const paymentMethodsHaveBeenFetched = paymentMethods.creditCards.length !== 0 || paymentMethods.paypal.length !== 0;

  if (!paymentMethodsHaveBeenFetched) {
    await new Promise((resolve, reject) => {
      dispatch(getPaymentMethodsPending({ onSuccess: resolve, onError: reject }));
    })
      .catch((error) => Promise.reject({
        errorSource: 'error-getting-payment-methods',
        error
      }));
  }
};

const getFormattedPaymentMethod = (state, source) => {
  const sourcePaymentMethod = source.paymentMethod;
  const organizationId = getOrganizationId(source);

  if (sourcePaymentMethod.paymentType === 'Account') {
    return {
      id: sourcePaymentMethod.id,
      account: sourcePaymentMethod.account,
      organizationId: sourcePaymentMethod.organizationId,
      billingAccountId: sourcePaymentMethod.billingAccountId,
      name: sourcePaymentMethod.organizationName,
    };
  } else {
    try {
      const { paymentMethod, type: paymentMethodType } = getMatchingPaymentMethod(state, {
          paymentMethodId: sourcePaymentMethod.id,
          organizationId
        });
      return formatAutoshipPaymentMethod(paymentMethodType, paymentMethod);
    } catch (error) {
      throw {
        errorSource: 'no-matching-payment-method',
        error
      };
    }
  }
};

const buildCustomer = (user, existingCustomer) => {
  if (existingCustomer.customerType === 'Organization') {
    return {
      ...existingCustomer,
      contactPerson: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }

    };
  } else {
    return {
      customerType: 'Individual',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  }
};

const createNewAutoship = async ({ httpClient, getState, action }, dispatch) => {
  const { sourceId, newAutoshipName, productSku, newQuantity } = action.payload;

  let state = getState();

 const source = state.app.getIn(['autoships', 'details', sourceId, 'data'])?.toJS();

  if (source == null) {
    return Promise.reject({
      errorSource: 'no-source-autoship',
      error: new Error(`unable to find autoship matching id ${sourceId}`)
    });
  }

  const item = source.items.find(item => item.productSku === productSku);

  if (source.paymentMethod.paymentType !== 'Account') {
    await ensurePaymentMethodsHaveBeenFetched({ getState, dispatch });
    state = getState();
  }
  const paymentMethod = getFormattedPaymentMethod(state, source);
  const user = getCurrentUser(state);

  const isQuarterly = source.subscriptionType.subtype === 'Quarterly';
  const autoshipUrl = isQuarterly
    ? 'autoships/quarterly'
    : 'autoships/custom';

  return httpClient(dispatch).post(autoshipUrl, {
      customer: buildCustomer(user, source.customer),

      // subscription service throws an error if autoshipName is an empty string
      autoshipName: newAutoshipName !== '' ? newAutoshipName : undefined,
      channel: source.channel,
      items: [{
          productSku,
          quantity: newQuantity ?? item.quantity,
          promotionCode: item.promotionCode
        }],
    scheduledOrderDate: isQuarterly
      ? undefined
      : source.scheduledOrderDate,
      billingFrequency: source.billingFrequency,

      paymentMethod,

      shippingAddress: source.shippingAddress,
      billingAddress: source.billingAddress,
    })
    .then((resp) => resp.data.subscriptionId)
    .catch(error => Promise.reject({ errorSource: 'error-create-new', error }));
};

const removeItemFromSource = async ({ action }, dispatch) => {
  const { sourceId, productSku, organizationId } = action.payload;

  return new Promise((resolve, reject) => {
    dispatch(removeAutoshipItemPending(
        sourceId,
        { productSku, organizationId },
        { onSuccess: resolve, onError: reject, refetchAutoships: false }
      ));
  })
    .catch(error => Promise.reject({ errorSource: 'error-remove-from-source', error }));
};

export const transferItemToNew = createLogic({
  type: TRANSFER_AUTOSHIP_ITEM_TO_NEW_PENDING,

  process({ httpClient, getState, action }, dispatch, done) {
    const { sourceId, organizationId } = action.payload;

    createNewAutoship({ httpClient, getState, action }, dispatch)
      .then(newAutoshipId =>
        removeItemFromSource({ action }, dispatch)
          .then(() => newAutoshipId)
          .catch(error => Promise.reject({ ...error, newAutoshipId }))
      )
      .then((newAutoshipId) => {
        dispatch(transferAutoshipItemToNewSuccess({ ...action.payload, newAutoshipId }));

        refetchUpdatedAutoships(dispatch, [
          { autoshipId: sourceId, organizationId },
          { autoshipId: newAutoshipId, organizationId }
        ]);

        action.meta?.onSuccess?.(newAutoshipId);
      })
      .catch((e) => {
        const { errorSource, error, newAutoshipId } = e.errorSource == null
        ? { errorSource: 'unknown', error: e }
        : e;

    dispatch(transferAutoshipItemToNewError({ ...action.payload, newAutoshipId }, errorSource, error));

        if (newAutoshipId) {
          refetchUpdatedAutoships(dispatch, [{ autoshipId: newAutoshipId, organizationId }]);
        }

        action.meta?.onError?.(errorSource, error, newAutoshipId);
      })
      .finally(() => done());
  }
});

export const addItemsToAutoship = createLogic({
  type: ADD_AUTOSHIP_ITEMS_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { autoshipId, organizationId, items } = action.payload;

    const autoshipItems = items.map(item => ({
      productSku: item.itemNumber,
      quantity: item.quantity
    }));

    httpClient(dispatch).post(`/subscriptions/${autoshipId}/items`, { items: autoshipItems })
      .then(() => {
        dispatch(addAutoshipItemsSuccess(autoshipId, { organizationId, items }));

        if (action.meta.refetchAutoships) {
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });
        }

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(addAutoshipItemsError(autoshipId, { organizationId, items }, error));
        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export const changeFrequency = createLogic({
  type: CHANGE_AUTOSHIP_FREQUENCY_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId, autoshipId, frequency } = action.payload;

    httpClient(dispatch).put(`/subscriptions/${autoshipId}/billingfrequency`, {
        id: autoshipId,
        billingFrequency: {
        interval: typeof frequency === 'number'
          ? frequency
          : parseInt(frequency, 10),
        intervalType: 'months'
      }
      })
      .then(() => {
        dispatch(changeAutoshipFrequencySuccess(autoshipId, frequency));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(changeAutoshipFrequencyError(error));
        action.meta?.onError?.();
      })
      .finally(() => done());
  }
});

export const changeNextOrderDate = createLogic({
  type: CHANGE_AUTOSHIP_NEXT_ORDER_DATE_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId, autoshipId, nextShipDate } = action.payload;

    httpClient(dispatch).put(`subscriptions/${autoshipId}/orderdate`, {
      nextScheduledOrderDate: moment(nextShipDate, PARSE_DATE_FORMAT).format('YYYY-MM-DD')
      })
      .then(() => {
        dispatch(changeAutoshipNextOrderDateSuccess(autoshipId, nextShipDate));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(changeAutoshipNextOrderDateError(error));
        action.meta?.onError?.();
      })
      .finally(() => done());
  }
});

export const changeName = createLogic({
  type: CHANGE_AUTOSHIP_NAME_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId, autoshipId, newName } = action.payload;

    httpClient(dispatch).put(`subscriptions/${autoshipId}/name`, {
        id: autoshipId,
        name: newName
      })
      .then(() => {
        dispatch(changeAutoshipNameSuccess(autoshipId, newName));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(changeAutoshipNameError(error));
        action.meta?.onError?.();
      })
      .finally(() => done());
  }
});

export const submitOrder = createLogic({
  type: SUBMIT_AUTOSHIP_ORDER_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { organizationId, autoshipId, preserveNextOrderDate } = action.payload;

    httpClient(dispatch).post(`subscriptions/${autoshipId}/submitorder`, { preserveNextOrderDate })
      .then(() => {
        dispatch(submitAutoshipOrderSuccess(autoshipId, preserveNextOrderDate));

        if (!preserveNextOrderDate) {
          refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });
        }

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(submitAutoshipOrderError(error));
        action.meta?.onError?.();
      })
      .finally(() => done());
  }
});

export const skipNextOrder = createLogic({
  type: SKIP_AUTOSHIP_NEXT_ORDER_PENDING,

  process({ httpClient, action }, dispatch, done) {
    const { autoshipId, organizationId } = action.payload;

    httpClient(dispatch).post(`subscriptions/${autoshipId}/skipnextshipment`, {})
    .then(resp => {
        dispatch(skipAutoshipNextOrderSuccess(autoshipId, organizationId));

        refetchUpdatedAutoships(dispatch, { autoshipId, organizationId });

        action.meta?.onSuccess?.();
      })
      .catch((error) => {
        dispatch(skipAutoshipNextOrderError(autoshipId, organizationId, error));
        action.meta?.onError?.();
      })
      .finally(() => done());
  }
});

export const getDefaultBillingAccountId = createLogic({
  type: GET_DEFAULT_BILLING_ACCOUNT_ID_PENDING,
  process({ httpClient, action }, dispatch, done) {
    const { organizationId } = action.payload;

    httpClient(dispatch)
      .get(`/organizations/${organizationId}/billing-accounts/default`)
      .then((res) => {
        dispatch(getDefaultBillingAccountIdSuccess(res.data.id));
        done();
      })
      .catch((error) => {
        dispatch(getDefaultBillingAccountIdError({ err: error }));
        done();
      });
  },
});

export default [
  getPersonalAutoships,
  getOrgAutoships,
  getAutoshipDetails,
  updateAutoshipShippingAddress,
  updateAutoshipPaymentMethod,
  updateAutoshipContact,
  cancelAutoshipLogic,
  reactivateAutoshipLogic,
  removeAutoshipItem,
  changeItemQuantity,
  transferItemToExisting,
  transferItemToExistingWithQuantity,
  transferItemToNew,
  addItemsToAutoship,
  changeFrequency,
  changeNextOrderDate,
  changeName,
  submitOrder,
  skipNextOrder,
  getDefaultBillingAccountId
];
